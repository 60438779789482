
















































































.main-logo-container:not(.plain) {
  padding: 2%;
  padding-bottom: 1%;
  border-bottom: 1px solid #ccc;
  text-align: center;
  margin-bottom: 16px;
  svg {
    max-width: 40%;
    #Ebene_2-2 path {
      fill: #b4b4b4;
    }
  }
}
