



























































































































































































.super-admin-dashboard {
  .b-table .table-wrapper {
    height: 600px !important;
  }
  .visitor-charts-wrapper {
    height: 400px;
  }
}
