// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


// Set your colors
$primary: #4290ce;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$sidebar-background: #2080C5;
$pink: #FF3860;

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Menu
$menu-item-active-background-color: rgba(32, 128, 197, .1);
$menu-item-active-color:#2080C5;
$menu-item-hover-background-color: $menu-item-active-background-color;
$menu-item-hover-color: #209CEE;;
$menu-list-link-padding: 1em 1.25em;
$menu-item-color: #777777;

.menu.main-menu {
  ul li {
    border-bottom: 1px solid #c5c5c5;
  }
}

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
    "sidebar-background": (
      $sidebar-background,
      $sidebar-background,
      $sidebar-background,
      $sidebar-background,
    )
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.app-content.with-sidebar {
  padding-left: 290px;
  padding-right: 14px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Ubuntu', sans-serif;
}

/* Login / Registration */
html body.blueBg {
  background: transparent radial-gradient(closest-side at 50% 50%, #2080C5 0%, #115B8F 83%, #10588B 100%) 0% 0% no-repeat padding-box;
  .logo-cols {
    margin-top: 10vh;
  }

  .login-card-footer {
    border-top: none;
    border-bottom: 1px solid #ededed;

    .card-footer-item {
      color: $primary;
      cursor: pointer;
      &:hover,
      &.active {
        color: #ccc;
      }
    }
  }
}
.input[disabled] {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.card.no-border-radius {
  border-radius: 0;
}

// Absolute positioned field messages
.field {
  position: relative;
  padding-bottom: 20px;
  &:not(:last-child) {
    margin-bottom: 0.35rem;
  }
}
p.help {
  position: absolute;
  top: calc(100% - 18px);
  left: 0;
}


hr {
  height: 1px;
  background: #ccc;
}
.modal-card-foot {
  background: #fff;
}

#tablet-mobile-menu {
  display: none;
}
#tablet-mobile-menu {
  .main-logo-container:not(.plain) svg {
    max-width: 100%;
  }
  .main-logo-container {
    min-width: 200px;
    width: 100%;
    padding: 5px;
    border: none;
    margin: 0;
    .navbar-item, .navbar-link {
      color: #fff;
      background-color: #363636;
    }
  }
}
iframe[name="print-qr-code-iframe"] {
  display: none;
}
@media only screen and (max-width: 1180px) {
  .venues {
    .venue-cart-inner-wrapper {
      .column {
        display: block;
        width: 100%;
        &.is-one-quarter {
          border: none;
        }
      }
      footer {
        &.level {
          display: block;
        }
        .level-left, .level-right {
          display: block;
        }
        .is-success {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .dashboard {
    .level-left, .level-right, .level {
      display: block;
    }
  }
}
@media only screen and (max-width: 768px) {
  .dashboard {
    .level-left, .level-right, .level {
      display: flex;
      margin-top: 0px;
    }
  }
  .mydata {
    button.is-danger, button.is-success {
      width: 100%;
    }
  }
  .app-content.with-sidebar {
    padding-left: 0px;
    padding-right: 0px;
  }
  .abonnement .products {
    display: block;
    .card {
      margin-top: 25px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  #tablet-mobile-menu {
    display: block;
    #logout {
      position: relative;
      &.navbar-item {
        color: #fff;
      }
    }
    .navbar-item, .navbar-link {
      display: flex;
      .nav-nar-title {
        margin-left: 5px;
      }
    }
  }
}

// Recaptcha badge
body:not(.registration) {
  .grecaptcha-badge {
    display: none !important;
    margin: 0;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}
.editvenue, .newvenue {
  .vc-sketch {
    position: absolute;
    right: 0;
    bottom: 100%;
    z-index: 10;
  }
  .tab-content .message-body {
    display: flex;
  }
}
div.b-sidebar .sidebar-content.is-fullheight {
  position: fixed;
}

.select select[disabled] {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  &:hover {
    border-color: #f5f5f5;
  }
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #363636;
}