
















































.login.container {
  .bottom-text {
    color: #3E98D9;
    a {
      color: #3E98D9;
    }
  }
  .einfachgast-logo {
    max-width: 480px;
  }
  .slot-content {
    width: 480px;
  }
  @media only screen and (max-width: 480px) {
    .slot-content {
      width: 360px;
    }
    .einfachgast-logo {
      max-width: 360px;
    }
  }
}
