

















































.logo-wrapper {
  padding: 16px 52px 0 52px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
}
