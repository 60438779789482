


























































.menu-column {
  min-width: 250px;
}
.abo-invoice-li {
  position: relative;
}
.logout-wrapper {
  position: absolute;
  border: 0;
  border-top: 1px solid #c5c5c5;
  display: block;
  bottom: 0;
  width: 100%;
}
